<template>
  <div id="talent-dashboard">
    <v-app-bar app color="white" light flat>
      <v-app-bar-nav-icon
        v-if="!sidebarMenu&&!$device.mobile"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
      <MobileNavBar v-if="$device.mobile" />
      <v-spacer />
      <NotificationButton />
      <v-menu offset-y class="flex-column" left>
        <template #activator="{ on, attrs }">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-avatar class="rounded ma-0" size="47">
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="ProfileImg"
                  :src="profileImage"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item to="/talent/dashboard">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/account/settings">
            <v-list-item-title>{{ $t('account_settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Sidebar -->
    <NavBarList :sidebar.sync="sidebarMenu" />
    <!-- Main Container -->
    <v-container
      class="py-0 fill-height dashboard-container grey_regular"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" color="transparent" tile elevation="0">
            <v-card-title class="mt-2 pb-0 text-h3">
              Hello
              <span class="text-capitalize pl-1">{{ user.firstName }}</span
              >,
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fill-height">
        <v-col cols="8" sm="12" md="8" lg="8" xl="8">
          <v-row>
            <v-col md="4" sm="6">
              <v-card
                class="px-4 py-3 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-50">
                    {{ $t('total_earnings') }}
                  </div>
                  <v-skeleton-loader v-if="loading" type="heading" />
                  <p v-else class="text-h5 black--text mb-0">
                    ${{ totalEarning }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4" sm="6">
              <v-card
                class="px-4 py-3 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-50">
                    {{ $t('completed_sessions') }}
                  </div>
                  <v-skeleton-loader v-if="loading" type="heading" />
                  <p v-else class="text-h5 black--text mb-0">
                    {{ totalCompletedSessions }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4" sm="6">
              <v-card
                class="px-4 py-3 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-50">
                    {{ $t('total_aptmnt') }}
                  </div>
                  <v-skeleton-loader v-if="loading" type="heading" />
                  <p v-else class="text-h5 black--text mb-0">
                    {{ totalAppointments }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <v-card
                class="py-3 dashboard-card rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-title class="text-h5">
                  {{ $t('recent_booking') }}
                </v-card-title>
                <v-card-text class="booking-inner-card">
                  <template v-if="loading">
                    <v-skeleton-loader
                      v-for="n in number"
                      :key="n"
                      type="list-item-avatar-two-line"
                    />
                  </template>
                  <v-list v-else two-line>
                    <template v-for="session in sessionLists">
                      <v-list-item :key="session.id" class="px-0">
                        <v-list-item-avatar
                          color="grey darken-3"
                          class="rounded"
                          size="48"
                        >
                          <v-skeleton-loader v-if="loading" type="avatar" />
                          <v-img
                            class="elevation-6"
                            alt="ProfileImg"
                            :src="userProfileImage(session.guest.profileImg)"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content class="mt-n2">
                          <v-list-item-title
                            :title="
                              ` ${session.guest.firstName} ${session.guest.lastName} Booked`
                            "
                          >
                            <span class="text-subtitle-1">
                              {{ session.guest.firstName }}
                              {{ session.guest.lastName }}
                            </span>
                            <span class="text-subtitle-3 pl-1">Booked</span>
                          </v-list-item-title>

                          <v-list-item-subtitle
                            :title="session.sessionType.title"
                            class="text-subtitle-2 text--opacity-75"
                            v-text="session.sessionType.title"
                          />
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text
                            class="text-body-1 text--opacity-75"
                          >
                            {{ session.createdOn | timeAgo }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="white"
                    :ripple="false"
                    to="/talent/appointments"
                  >
                    {{ $t('view_all') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <v-card
                class="py-3 dashboard-card rounded"
                color="grey"
                tile
                elevation="0"
              >
                <v-card-title class="text-h5">
                  {{ $t('today_aptmnt') }}
                  <v-badge
                    v-if="!loading && totalToday > 0"
                    color="orange"
                    :content="totalToday"
                    inline
                    class="pl-1"
                  />
                </v-card-title>
                <v-card-text class="booking-inner-card">
                  <template v-if="loading">
                    <v-skeleton-loader
                      v-for="n in number"
                      :key="n"
                      type="list-item-avatar-two-line"
                    />
                  </template>
                  <v-list v-else two-line class="grey">
                    <template v-for="session in sessionsToday">
                      <v-list-item :key="session.id" class="px-0">
                        <v-list-item-avatar
                          color="grey darken-3"
                          class="rounded"
                          size="48"
                        >
                          <v-img
                            class="elevation-6"
                            alt="ProfileImg"
                            :src="userProfileImage(session.guest.profileImg)"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content class="mt-n2">
                          <v-list-item-title
                            class="text-subtitle-1"
                            :title="
                              `${session.guest.firstName} ${session.guest.lastName}`
                            "
                            v-text="
                              `${session.guest.firstName} ${session.guest.lastName}`
                            "
                          />
                          <v-list-item-subtitle
                            class="text-subtitle-2 text--opacity-75"
                            :title="session.sessionType.title"
                            v-text="session.sessionType.title"
                          />
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text
                            class="text-body-1 text--opacity-75"
                          >
                            {{ $t('at') }}
                            {{ `${session.startTime}` | formatDate('hh:mm a') }}
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="white"
                    :ripple="false"
                    to="/talent/appointments"
                  >
                    {{ $t('view_all_aptmnt') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" sm="12" md="4" lg="4" xl="4">
          <v-card class="payment-card rounded" color="white" tile elevation="0">
            <v-card-title class="text-h5">
              {{ $t('payment_history') }}
            </v-card-title>
            <v-card-text class="grey_medium payment-inner-card">
              <template v-if="loading">
                <v-skeleton-loader
                  v-for="n in number"
                  :key="n"
                  type="list-item-two-line"
                />
              </template>
              <v-list v-else two-line class="grey_medium">
                <template v-for="payment in recentPayments">
                  <v-list-item :key="payment.payoutid" class="px-0">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-subtitle-2 text--opacity-75"
                        v-text="
                          `${payment.paidBy.firstName} ${payment.paidBy.lastName}`
                        "
                      />
                      <v-list-item-subtitle
                        class="text-subtitle-2 text--opacity-50"
                      >
                        {{ payment.createdOn | timeAgo }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text
                        class="subtitle-1"
                        v-text="`+$${payment.netPayout}`"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// component
import NotificationButton from '@/components/generic/NotificationButton.vue'
import MobileNavBar from '@/components/generic/MobileNavBar.vue'
import NavBarList from '@/pages/talent/dashboard/NavBarList.vue'

// graphql
import { GET_TALENT_DASHBOARD } from '@/graphql/talent'

export default {
  components: {
    NotificationButton,
    MobileNavBar,
    NavBarList,
  },
  data() {
    return {
      number: 5,
      sidebarMenu: true,
      toggleMini: false,
      sessionLists: [],
      sessionsToday: [],
      recentPayments: [],
      totalCompletedSessions: 0,
      totalToday: 0,
      totalEarning: 0,
      totalAppointments: 0,
      loading: false,
      icon: null,
      defaultImg: `https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black
      &facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light`,
    }
  },
  computed: {
    ...mapGetters(['profileImage', 'user', 'jwt']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
    userProfileImage() {
      return profileImage => (profileImage ? profileImage : this.defaultImg)
    },
  },
  beforeMount() {
    this.getDashboardInfo()
    this.icon = '/images/icon/talent/menu.png'
    if(this.$device.mobile) this.sidebarMenu = false;
  },
  methods: {
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },

    /**
     * Get Dashboard Info
     */
    async getDashboardInfo() {
      this.loading = true
      try {
        const {
          data: { getTalentDashboard },
        } = await this.$apollo.query({
          query: GET_TALENT_DASHBOARD,
          variables: {
            talentInput: {
              email: this.user.email,
              accesstoken: this.jwt,
            },
            tzinfo: this.$defaultTZ,
          },
        })

        if (getTalentDashboard.success) {
          this.sessionLists = getTalentDashboard.sessionList
          this.sessionsToday = getTalentDashboard.sessionToday
          this.recentPayments = getTalentDashboard.recentPayments
          this.totalCompletedSessions = getTalentDashboard.totalCompleteSession
          this.totalAppointments = getTalentDashboard.totalBooking
          this.totalToday = getTalentDashboard.totalToday
          this.totalEarning = getTalentDashboard.totalEarning
          this.loading = false
        } else {
          this.error = this.$t(getTalentDashboard.error)
          this.loading = false
        }
      } catch (e) {
        console.error(e) // eslint-disable-line
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.payment-inner-card {
  height: calc(100vh - 208px);
  overflow-y: auto;
}
#talent-dashboard {
  min-height: calc(100vh - 64px);
  background-color: #f8f9fb;
}
.dashboard-card {
  height: calc(100vh - 280px);
}
.desktopmenu {
  display: none;
}
.payment-card {
  height: calc(100vh - 154px);
}
.booking-inner-card {
  height: calc(100vh - 410px);
  overflow-y: auto;
}
.dashboard-container {
  margin-top: 9px;
}
</style>
<style lang="scss">
.payment-inner-card
  .v-skeleton-loader
  .v-skeleton-loader__list-item-two-line.v-skeleton-loader__bone {
  background: none !important;
}
@media only screen and (max-width: 959px) {
  nav.dektop-view.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .mobile-view {
    display: block;
    position: absolute;
    left: -42px;
  }
  .menuicons {
    width: 62%;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  // #app
  //   .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  //   min-width: 90% !important;
  //   max-width: 94% !important;
  // }
  .v-main {
    padding: 56px 0px 0px 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  .search-box.v-card.v-sheet.theme--light {
    display: none;
  }
  nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--mini-variant.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .v-image.v-responsive.elevation-6.theme--light {
    // display: block;
    display: block;
  }
}
</style>
